* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:Arial, Helvetica, sans-serif;
  font-size:15.3px;
}

.App {
  min-height: 100vh;
  padding: 0 20px;
  background-image: url("mountains_blue.jpeg");
  background-size: cover;
}

button {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #072361;
  cursor: pointer;
}


.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background-image: url("mountains_blue.jpeg");
  background-size: cover;
  display: flex;
}


.hero h5{
  padding-left: 4%;
}

.login .loginContainer {
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgb(4,86,111);
  background: linear-gradient(180deg, rgba(4,86,111,0.31836484593837533) 25%, rgba(255,255,255,0.2007177871148459) 90%);
  box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.login .loginContainer .image {
  justify-content: center;
}

.login .loginContainer label {
  color: white;
  margin: 14px 0;
  display: block;
  font-size: 18px;
  line-height: 1;
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px;
  background: rgba(8, 8, 8, 0.1);
  color: white;
  letter-spacing: 1px;
}
.login .loginContainer input:focus {
  animation: glowing 1s linear infinite alternate;
  -webkit-animation: glowing 1s linear infinite alternate;
  transition: 1s all;
}

.login .loginContainer input:hover {
  animation: glowing 1s linear infinite alternate;
  -webkit-animation: glowing 1s linear infinite alternate;
  transition: 1s all;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
}



.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
  color: black;
}

.login .loginContainer .btnContainer p span {
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .errorMsg {
  color: rgb(182, 59, 59);
  font-size: 16px;
}

.hero {
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(
    ellipse at left bottom,
    rgba(88, 190, 245, 0.324) 50%,
    rgba(78, 92, 170, 0.9) 100%
  );
}

.hero nav {
  background: rgba(175, 214, 226, 0.228) 25%;
  background: linear-gradient(180deg, rgba(6, 43, 54, 0.228) 25%, rgba(168, 173, 247, 0) 200%);
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero nav h2 {
  color: #fff;
  font-size: 30px;
}

.hero nav button {
  width: 86px;
  padding: 8px 0;
  background: #072361;
}

.hero div.jumbotron-fluid{
  height: 2px;
  padding-top: 2%;
  padding-bottom: 10%;
  background: rgb(51, 71, 114);
  color: white;
}


.hero button.midButton{
  margin-left: 52%;
}

.hero button:focus{
  outline: none !important;
  border: none !important;
  box-shadow: none;
}

.hero .btn-primary:focus{
  outline: none !important;
  border: none !important;
  box-shadow: none;
}

@keyframes glowing {
  from {
    box-shadow: inset 0 0 20px 20px #e3e7f046, 0 0 50px 10px #e3e7f04a, inset 0 0 80px 80px #e3e7f041;
  }
  to {
    box-shadow: inset 0 0 20px 20px #e3e7f04f, 0 0 100px 10px #e3e7f05d, inset 0 0 50px 50px #e3e7f068;
  }
}

@-webkit-keyframes glowing {
  from {
    box-shadow: inset 0 0 20px 20px #e3e7f046, 0 0 50px 10px #e3e7f06f, inset 0 0 80px 80px #e3e7f066;
  }
  to {
    box-shadow: inset 0 0 20px 20px #e3e7f0, 0 0 130px 10px #e3e7f0, inset 0 0 50px 50px #e3e7f0;
  }
}

.form-control {
  background-color: rgba(8, 8, 8, 0.1);
  color: white;
  font-size: 18px;
  width: 300px;
}

.btn-primary, .btn-primary:hover, .btn-primary:active {
  background-color: #072361 !important;
}

.contact ::placeholder {
  color: white;
  display: block;
  font-size: 18px;
  line-height: 1;
}

.contact .contactContainer .errorMsg {
  color: rgb(182, 59, 59);
  font-size: 16px;
  margin-left: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.input-group-prepend {
  width: 50px;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.input-group-text {
  width: 40px;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.contact .errorMsg {
  color: rgb(182, 59, 59);
  font-size: 16px;
  margin-left: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.quotebtn {
  margin-bottom: 10px;
}

.pricepops {
  margin-bottom: 25px;
}
